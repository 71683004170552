import { useEffect, useRef } from 'react'

/**
 * A setInterval hook that calls a callback after a interval duration
 *
 * @param callback The callback to be invoked after interval
 * @param intervalDurationMs Amount of time in ms after which to invoke
 * @param startImmediate If the callback should be invoked immediately
 */
const useInterval = (
  callback_: () => void,
  intervalDurationMs = 1000,
  startImmediate = true
): void => {
  const savedRefCallback = useRef<() => unknown>()

  useEffect(() => {
    savedRefCallback.current = callback_
  })

  const callback = (): void => {
    savedRefCallback.current && savedRefCallback.current()
  }

  useEffect(() => {
    if (startImmediate) {
      callback()
    }
    const interval = setInterval(callback, intervalDurationMs)

    return (): void => {
      clearInterval(interval)
    }
  }, [startImmediate, intervalDurationMs])
}

export default useInterval
