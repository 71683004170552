import React from 'react'

import { getHeightElement } from '../helpers/dom/getHeightElement'
import { useComponentDidMount } from './useComponentDidMount'

const useElementHeight = <T extends HTMLElement>(
  elementRef: React.RefObject<T>,
  onChangeHeight: (theadHeight: number) => void
): void => {
  // TODO: resize case
  useComponentDidMount(() => onChangeHeight(getHeightElement(elementRef.current)))
}

export default useElementHeight
