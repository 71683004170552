import { useEffect, useRef } from 'react'

const useIsFirstRender = (): boolean => {
  const isFirstRender = useRef<boolean>(true)

  useEffect(() => {
    isFirstRender.current = false
  }, [])

  return isFirstRender.current
}
export default useIsFirstRender
