import { hooks, WalletConnectors } from '@telekomconsalting/react-dexguru-wallet'

import { NetworkConfigV3 } from '../model'

const useLazyEagerConnect = (
  suppress: boolean,
  connectors: WalletConnectors | undefined,
  initConnectors: (networksConfig: NetworkConfigV3[]) => void,
  networksConfig: NetworkConfigV3[]
): void => {
  if (!suppress && !connectors) {
    initConnectors(networksConfig)
  }

  hooks?.useEagerConnect(suppress, connectors)
}

export default useLazyEagerConnect
