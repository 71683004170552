import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Currency, TokenWithApprovalContext } from '../../model'
import { ConnectionType } from '../../services/WebsocketService/type'
import websocketServiceContext from '../../services/WebsocketService/wsContext'
import reSubscribeTokenPrice, { WsChannelNames } from './reSubscribeTokenPrice'

const useTokenPrice = ({
  currency,
  token,
  channel,
}: {
  currency: Currency
  token?: TokenWithApprovalContext
  channel: WsChannelNames
}): void => {
  const { ws } = useContext(websocketServiceContext)
  const reduxDispatch = useDispatch()

  useEffect(() => {
    if (ws?.state !== ConnectionType.READY || !token?.id) {
      return
    }

    reSubscribeTokenPrice({
      token: {
        id: token.id,
        address: token.address,
      },
      reduxDispatch,
      currency,
      channel,
      ws,
    })
  }, [token?.id, token?.address, reduxDispatch, currency, channel, ws])
}

export default useTokenPrice
