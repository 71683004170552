import { AnyAction, Dispatch } from 'redux'

import { updateTokenPrices } from '../../actions/tokenActions'
import { buildChannelKey } from '../../components/TVChartContainer/datafeed/helper'
import { Currency } from '../../model'
import {
  ConnectionHandler,
  SubscribeItemType,
  SubscriptionItem,
} from '../../services/WebsocketService/type'
import { WebSocketClient } from '../../services/WebsocketService/websocketService'
import { updateBars } from '../../services/wsTVChartService'
import { TokenPriceRecord } from './useTokenPrice.types'

export enum WsChannelNames {
  priceCurrent = 'price_current',
  priceQuote = 'price_quote',
}

const WS_PRICE_DELAY = 10000 * 60
const getCurrentDateWSPrice = (): number =>
  Math.floor((new Date().valueOf() - WS_PRICE_DELAY) / 1000)

const reSubscribeTokenPrice = ({
  token,
  currency,
  channel,
  reduxDispatch,
  ws,
}: {
  token?: TokenPriceRecord
  currency: Currency
  reduxDispatch: Dispatch<AnyAction>
  channel: WsChannelNames
  ws: WebSocketClient
}): void => {
  if (token?.id) {
    const channelId = buildChannelKey(`${token?.id}_${currency}`, '10') || ''
    ws.unsubscribe(channel)

    const handler = {
      uuid: channel,
      reconnect: false,
      onMessage: (
        data: unknown,
        subscriptionItem?: SubscriptionItem,
        handler?: ConnectionHandler
      ): void => {
        const bar = updateBars(data, subscriptionItem, handler)
        if (bar) {
          reduxDispatch(
            updateTokenPrices([
              {
                address: token.address,
                token_price: bar.close,
                currency,
              },
            ])
          )
        }
      },
      params: { lastBar: getCurrentDateWSPrice },
    }

    ws.subscribe(channelId, SubscribeItemType.chart, {}, handler)
  }
}

export default reSubscribeTokenPrice
